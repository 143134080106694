import { parse, stringify } from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../common/components/Loader';
import {
  getSitePiDetailsFromTrialAction,
  getSitePiDetailsFromTrialResetAction,
} from '../DeepDiveScreen/logic';
import { Tooltip, Button, Modal, Popover, Radio } from 'antd';
import StarRatings from 'react-star-ratings';
import ReadMore from '../../../common/components/ReadMore';
import InputWithCopy from '../CopyToClipboard';
import {
  getProjectsDataAction,
  getProjectsDataMoreAction,
  getProjectsDataResetAction,
} from '../MyProjects/logic';
import LazyLoadDiv from '../../../common/components/LazyLoadDiv/index';
import { getFormattedDate, getConcatenatedString, getCookie, isViewOnlyAccess } from '../../../utils';
import {addPiForCTAction, addPiResetForCTAction} from './logic'
import { getProjectCountsAction } from '../Search/logic';
import Axios from 'axios';

export default function CTDeepDiveInvestigatorDetails() {
  const dispatch = useDispatch();
  const [tabsTrialDataObj, setTabsTrialDataObj] = useState([]);
  const [from, setFrom] = useState(1);
  const [tableData, settableData] = useState([]);
  const [isFiltersPopoverVisible, setIsFiltersPopoverVisible] = useState(false);
  const [addInvestigatorToProjectModal, setAddInvestigatorToProjectModal] = useState(false);
  const [activeModalTab, setActiveModalTab] = useState('created');
  const [filters, setFilters] = useState('');
  const [id, setId] = useState('');
  const [loading, setLoading] = useState(false);
  const [activeToggle, setActiveToggle] = useState('all');
  const [activeToggleReview, setActiveToggleReview] = useState('all');

  //  site_id: listData[index].affiliated_site_id,
  // pi_id: listData[index].id,
  // is_cfl: adminType.includes('cfl_access') ? true : undefined,
  // is_lcor: adminType.includes('lcor_access') ? true : undefined,
  const [piId, setPiId] = useState('');
  const [siteId, setSiteId] = useState('');
  const [projectId, setProjectId] = useState('');
  const { cid } = parse(location.search);
  const [countsData, setCountsData] = useState({});
  const [warningModal, setWarningModal] = useState(false);
  let nctId;
  if (Array.isArray(cid)) {
    nctId = cid.filter((option) => option.startsWith('NCT'));
  } else {
    nctId = cid;
  }

  const getSitePiDetailsFromTrialResponse = useSelector(
    (store) => store.getSitePiDetailsFromTrialResponse
  );
  const projectsData = useSelector((state) => state.projectsData);
  const addPiResponse = useSelector((store) => store.addPiForCTResponse);
  const sharedProjects = useSelector((state) => state.projectsData);
  const projectCountsData = useSelector((state) => state.projectCountsData);
  const adminType = getCookie('permissions');

  const Payment = [
    {
      category: 'With Payment',
      key: 'withPayment',
      id: 'With Payment',
    },
    {
      category: 'Without Payment',
      key: 'withoutPayment',
      id: 'Without Payment',
    },
  ];

  const columns = [
    {
      title: 'Project Name',
      dataIndex: 'name',
      additionalClass: 'u-flex-grow3 proj-name-col',
      isArray: false,
    },
    {
      title: 'Indication',
      dataIndex: 'indications',
      isArray: true,
      additionalClass: 'u-flex-grow2 indication-col',
    },
    {
      title: 'Sponsor',
      dataIndex: 'sponsors',
      isArray: true,
      additionalClass: 'u-flex-grow2 sponsors-col',
    },
    {
      title: '# Analysis',
      dataIndex: 'analysis_count',
      additionalClass: 'u-flex-grow1 no-analysis-col',
      isArray: false,
    },
    {
      title: 'Status',
      dataIndex: 'current_status',
      additionalClass: 'u-flex-grow1 status-col',
      isArray: false,
    },
    {
      title: 'Created Date',
      dataIndex: 'created_on',
      additionalClass: 'u-flex-grow1 date-col',
      isArray: false,
    },
    {
      title: 'Last Modification Date',
      dataIndex: 'modification_activity',
      additionalClass: 'u-flex-grow4 lm-date-col',
      isArray: false,
    },
    {
      title: '',
      dataIndex: 'favourite',
      additionalClass: 'u-flex-grow1 bookmark-col',
      isArray: false,
    },
  ];

  const columnsForSharedProoject = [
    {
      title: 'Project Name',
      dataIndex: 'name',
      additionalClass: 'u-flex-grow3 proj-name-col',
      isArray: false,
    },
    {
      title: 'Indication',
      dataIndex: 'indications',
      isArray: true,
      additionalClass: 'u-flex-grow2 indication-col',
    },
    {
      title: 'Sponsor',
      dataIndex: 'sponsors',
      isArray: true,
      additionalClass: 'u-flex-grow2 sponsors-col',
    },
    {
      title: '# Analysis',
      dataIndex: 'analysis_count',
      additionalClass: 'u-flex-grow1 no-analysis-col',
      isArray: false,
    },
    {
      title: 'Status',
      dataIndex: 'current_status',
      additionalClass: 'u-flex-grow1 status-col',
      isArray: false,
    },
    {
      title: 'Shared On',
      dataIndex: 'shared_details',
      subDataIndex: 'created_on',
      additionalClass: 'u-flex-grow4 lm-date-col',
      isArray: false,
    },
    {
      title: 'Shared By',
      dataIndex: 'shared_details',
      subDataIndex: 'shared_by',
      additionalClass: 'u-flex-grow3 shared-by',
      isArray: false,
    },
    {
      title: 'Review',
      dataIndex: 'shared_details',
      subDataIndex: 'review_completed',
      additionalClass: 'u-flex-grow2 review',
      isArray: false,
    },
    {
      title: '',
      dataIndex: 'favourite',
      additionalClass: 'u-flex-grow2 favourite',
      isArray: false,
    },
    // {
    //   title: '',
    //   dataIndex: 'view',
    //   additionalClass: 'u-flex-grow2 view-col',
    //   isArray: false,
    // },
  ];

  useEffect(() => {
    dispatch(
      getSitePiDetailsFromTrialAction({
        clinical_id: nctId,
        tabname: 'Investigators',
        paymentFilter: '',
      })
    );
    dispatch(getProjectCountsAction());
    dispatch(addPiResetForCTAction());
  }, []);

  useEffect(() => {
    if (projectCountsData.flag) {
      setCountsData(projectCountsData.data);
    }
  }, [JSON.stringify(projectCountsData)]);

  useEffect(() => {
    if (projectsData.flag) {
      settableData(projectsData.data.data);
      setLoading(false);
    }
  }, [JSON.stringify(projectsData)]);

  useEffect(() => {
    dispatch(
      getProjectsDataAction({
        page: 1,
        size: 10,
        type: activeModalTab,
        review_status: [activeToggleReview],
      })
    );
  }, [activeModalTab, activeToggleReview]);

  useEffect(() => {
    if (addPiResponse.flag && !addPiResponse.error) {
      setAddInvestigatorToProjectModal(false);
      setProjectId('');
      setPiId('');
      setSiteId('');
      setId('');
      dispatch(addPiResetForCTAction());
    }
    if (addPiResponse.error) {
      setAddInvestigatorToProjectModal(false);
      setProjectId('');
      setPiId('');
      setSiteId('');
      setId('');
      dispatch(addPiResetForCTAction());
    }
  }, [JSON.stringify(addPiResponse)]);

  const getStars = (value) => {
    if (value === 'Class 3') {
      return 1;
    }
    if (value === 'Class 2') {
      return 2;
    }
    if (value === 'Class 1') {
      return 3;
    }
    return 0;
  };

  useEffect(() => {
    if (getSitePiDetailsFromTrialResponse.flag) {
      setTabsTrialDataObj(getSitePiDetailsFromTrialResponse.data.data);
      dispatch(getSitePiDetailsFromTrialResetAction());
    }
  }, [JSON.stringify(getSitePiDetailsFromTrialResponse)]);

  const getDatefromTimestamp = (d) => {
    return new Date(d * 1000).toUTCString().substring(4, 16);
  };

  const updateFilters = (value) => {
    setFilters(value.key);
  };

  const onClearFilters = () => {
    setFilters('');
    dispatch(
      getSitePiDetailsFromTrialAction({
        clinical_id: nctId,
        tabname: 'Investigators',
        paymentFilter: '',
      })
    );
    setIsFiltersPopoverVisible(false);
  };

  const onApplyFilters = () => {
    dispatch(
      getSitePiDetailsFromTrialAction({
        clinical_id: nctId,
        tabname: 'Investigators',
        paymentFilter: filters,
      })
    );
    setIsFiltersPopoverVisible(false)
  };

  const renderHeader = () => {
    return (
      <>
        <div className="table-row table-header table-row-clickable">
          {columns.map((colItm, index) =>
            colItm.title !== '' ? (
              <div className={`table-row-item ${colItm.additionalClass}`} key={index}>
                {colItm.title}
              </div>
            ) : null
          )}
        </div>
      </>
    );
  };

  const renderFilters = () => {
    return (
      <div className="studies-filters-wrap">
        <div className="filter-itm-wrap">
          <div className="filter-title">Payment</div>
          <div className="filter-values-wrap">
            {Payment.map((filter, index) => (
              <div
                className={`filter-tag-wrap ${filters === filter.key ? 'active' : ''}`}
                onClick={() => updateFilters(filter)}
                role="presentation"
                key={index}
              >
                <div className="filter-name" title={filter.category}>
                  {filter.category}
                </div>
              </div>
            ))}
            {/* <div> With payment</div>
          <div> Without payment</div> */}
          </div>
        </div>
        <div className="filter-submit-btns">
          <Button className="clear-btn" onClick={onClearFilters}>
            Clear
          </Button>
          <Button
            className="apply-btn"
            type="primary"
            onClick={onApplyFilters}
            disabled={filters.length === 0}
          >
            Apply
          </Button>
        </div>
      </div>
    );
  };

  const loadMoreRows = () => {
    dispatch(
      getProjectsDataMoreAction({
        page: from + 1,
        size: 10,
        type: 'created',
      })
    );
    setFrom(from + 1);
  };
  const onChange = (tableItm, val) => {
    setId('');
    setProjectId('');
    if (val === 'shared') {
      if (tableItm.shared_details.access_type === 'EDIT') {
        setId(tableItm.project_id);
        setProjectId(tableItm.project_id);
        setWarningModal(false);
      } else {
        setWarningModal(true);
      }
    } else {
      setId(tableItm.project_id);
      setProjectId(tableItm.project_id);
    }
  };

  const renderRows = () => {
    let filteredData = tableData;
    if (activeToggle !== 'all') {
      filteredData = tableData.filter((status) => status.current_status === activeToggle);
    }
    return filteredData.map((tableItm, index) => (
      <div className="table-row" key={index}>
        <div className="table-row table-row-clickable" key={index} role="presentation">
          {columns.map((colItm, i) => {
            return (
              <>
                {colItm.dataIndex !== 'favourite' ? (
                  !colItm.isArray ? (
                    <div className={`table-row-item ${colItm.additionalClass}`} key={i}>
                      <span>
                        {colItm.dataIndex === 'modification_activity'
                          ? getFormattedDate(tableItm[colItm.dataIndex].last_modified_on)
                          : colItm.dataIndex === 'created_on'
                          ? getFormattedDate(tableItm[colItm.dataIndex])
                          : typeof tableItm[colItm.dataIndex] === 'number'
                          ? tableItm[colItm.dataIndex]
                          : tableItm[colItm.dataIndex]
                          ? tableItm[colItm.dataIndex]
                          : '-'}
                      </span>
                    </div>
                  ) : (
                    <div className={`table-row-item ${colItm.additionalClass}`} key={i}>
                      <span>
                        {tableItm[colItm.dataIndex].length > 0
                          ? typeof tableItm[colItm.dataIndex][0] === 'object'
                            ? getConcatenatedString(tableItm[colItm.dataIndex], 'name')
                            : tableItm[colItm.dataIndex].join('; ')
                          : '-'}
                      </span>
                    </div>
                  )
                ) : null}
              </>
            );
          })}
        </div>
        <div className="table-row table-row-notClickable" id={index}>
          {columns.map((colItm, i) => {
            return (
              <>
                {colItm.title === '' ? (
                  <div className={`table-row-item ${colItm.additionalClass}`} key={i}>
                    <div className="fav">
                      <Radio.Group onChange={() => onChange(tableItm, 'created')} value={id}>
                        <Radio value={tableItm.project_id} />
                      </Radio.Group>
                    </div>
                  </div>
                ) : null}
              </>
            );
          })}
        </div>
      </div>
    ));
  };

  const changeActiveToggle = (val) => {
    if (val === 'active') {
      setActiveToggle('active');
    } else if (val === 'invalid') {
      setActiveToggle('invalid');
    } else {
      setActiveToggle('all');
    }
  };

  const renderMyProject = () => {
    return (
      <Loader loading={projectsData.loading || loading}>
        <div
          className="toggle-switch-wrap toggle-switch-wrap-multiple my-project-toggle-switch"
          style={{ top: '23px', right: '40px' }}
        >
          <div
            className={`toggle-switch-itm ${activeToggle === 'all' ? 'active' : ''}`}
            role="presentation"
            onClick={() => changeActiveToggle('all')}
          >
            All
          </div>
          <div
            className={`toggle-switch-itm ${activeToggle === 'active' ? 'active' : ''}`}
            role="presentation"
            onClick={() => changeActiveToggle('active')}
          >
            Active
          </div>
          <div
            className={`toggle-switch-itm ${activeToggle === 'invalid' ? 'active' : ''}`}
            role="presentation"
            onClick={() => changeActiveToggle('invalid')}
          >
            Invalid
          </div>
        </div>
        <div className="table myproject-tab">
          <div className="table-row table-header">{renderHeader()}</div>
          <div className="table-content-wrap">
            <LazyLoadDiv
              className="card-list scrollbar"
              id="projects-list"
              total={projectsData.data.total}
              currentTotal={(tableData || []).length}
              loadMoreRows={() => loadMoreRows()}
              height="calc(100vh - 364px)"
            >
              {renderRows()}
            </LazyLoadDiv>
          </div>
        </div>
      </Loader>
    );
  };

  const renderHeaderForSharedProject = () => {
    return (
      <>
        <div className="table-row table-header table-row-clickable">
          {columnsForSharedProoject.map((colItm, index) =>
            colItm.title !== '' ? (
              <div className={`table-row-item ${colItm.additionalClass}`} key={index}>
                {colItm.title}
              </div>
            ) : null
          )}
        </div>
        <div className="table-row table-header table-row-notclickable">
          {columnsForSharedProoject.map((colItm, index) =>
            colItm.title === '' ? (
              <div className={`table-row-item ${colItm.additionalClass}`} key={index}>
                {colItm.title}
              </div>
            ) : null
          )}
        </div>
      </>
    );
  };

  const renderRowsForSharedProject = () => {
    let filteredData = tableData;
    if (activeToggle !== 'all') {
      filteredData = tableData.filter((status) => status.current_status === activeToggle);
    }
    return filteredData.map((tableItm, index) => (
      <div className="table-row" key={index}>
        <div className="table-row table-row-clickable" key={index} role="presentation">
          {columnsForSharedProoject.map((colItm, i) => {
            return (
              <>
                {colItm.dataIndex !== 'favourite' && colItm.dataIndex !== 'view' ? (
                  !colItm.isArray ? (
                    <div className={`table-row-item ${colItm.additionalClass}`} key={i}>
                      <span>
                        {colItm.dataIndex === 'shared_details' &&
                        colItm.subDataIndex === 'created_on'
                          ? getFormattedDate(tableItm?.shared_details?.created_on)
                          : colItm.dataIndex === 'shared_details' &&
                            colItm.subDataIndex === 'shared_by'
                          ? tableItm[colItm.dataIndex]?.shared_by_name
                          : colItm.dataIndex === 'shared_details' &&
                            colItm.subDataIndex === 'review_completed'
                          ? tableItm[colItm.dataIndex]?.review_completed === false
                            ? 'Not Completed'
                            : 'Completed'
                          : typeof tableItm[colItm.dataIndex] === 'number'
                          ? tableItm[colItm.dataIndex]
                          : tableItm[colItm.dataIndex]
                          ? tableItm[colItm.dataIndex]
                          : '-'}
                      </span>
                    </div>
                  ) : (
                    <div className={`table-row-item ${colItm.additionalClass}`} key={i}>
                      <span>
                        {tableItm[colItm.dataIndex].length > 0
                          ? typeof tableItm[colItm.dataIndex][0] === 'object'
                            ? getConcatenatedString(tableItm[colItm.dataIndex], 'name')
                            : tableItm[colItm.dataIndex].join('; ')
                          : '-'}
                      </span>
                    </div>
                  )
                ) : null}
              </>
            );
          })}
        </div>
        <div className="table-row table-row-notClickable" id={index}>
          {columnsForSharedProoject.map((colItm, i) => {
            return (
              <>
                {colItm.dataIndex === 'favourite' ? (
                  <div className={`table-row-item ${colItm.additionalClass}`} key={i}>
                    <div className="fav">
                      <Radio.Group onChange={() => onChange(tableItm, 'shared')} value={id}>
                        <Radio value={tableItm.project_id} />
                      </Radio.Group>
                      {/* <Bookmark isBookmarked={tableItm.isBookmarked.flag} type="projects" id={tableItm.project_id} bookmark_id={tableItm.isBookmarked.bookmark_id} /> */}
                    </div>
                  </div>
                ) : null}
              </>
            );
          })}
        </div>
      </div>
    ));
  };

  const loadMoreRowsForSharedProject = () => {
    dispatch(
      getProjectsDataMoreAction({
        page: from + 1,
        size: 10,
        type: 'shared',
        review_status: [activeToggleReview],
      })
    );
    setFrom(from + 1);
  };

  const renderSharedProject = () => {
    return (
      <Loader loading={sharedProjects.loading || loading}>
        <div
          className="toggle-switch-wrap toggle-switch-wrap-multiple my-project-toggle-switch my-project-toggle-switch-start"
          style={{ top: '25px', right: '304px' }}
        >
          <div
            className={`toggle-switch-itm ${activeToggle === 'all' ? 'active' : ''}`}
            role="presentation"
            onClick={() => changeActiveToggle('all')}
          >
            All
          </div>
          <div
            className={`toggle-switch-itm ${activeToggle === 'active' ? 'active' : ''}`}
            role="presentation"
            onClick={() => changeActiveToggle('active')}
          >
            Active
          </div>
          <div
            className={`toggle-switch-itm ${activeToggle === 'invalid' ? 'active' : ''}`}
            role="presentation"
            onClick={() => changeActiveToggle('invalid')}
          >
            Invalid
          </div>
        </div>
        <div
          className="toggle-switch-wrap toggle-switch-wrap-multiple my-project-toggle-switch my-project-toggle-switch-end"
          style={{ top: '25px', right: '47px' }}
        >
          <div
            className={`toggle-switch-itm ${activeToggleReview === 'all' ? 'active' : ''}`}
            role="presentation"
            onClick={() => setActiveToggleReview('all')}
          >
            All
          </div>
          <div
            className={`toggle-switch-itm ${activeToggleReview === 'completed' ? 'active' : ''}`}
            role="presentation"
            onClick={() => setActiveToggleReview('completed')}
          >
            Completed
          </div>
          <div
            className={`toggle-switch-itm ${
              activeToggleReview === 'not completed' ? 'active' : ''
            }`}
            role="presentation"
            onClick={() => setActiveToggleReview('not completed')}
          >
            Not Completed
          </div>
        </div>
        <div className="table shared-project-tab">
          <div className="table-row table-header">{renderHeaderForSharedProject()}</div>
          <div className="table-content-wrap">
            <LazyLoadDiv
              className="card-list scrollbar"
              id="projects-list"
              total={sharedProjects.data.total}
              currentTotal={(tableData || []).length}
              loadMoreRows={() => loadMoreRowsForSharedProject()}
              height="calc(100vh - 364px)"
            >
              {renderRowsForSharedProject()}
            </LazyLoadDiv>
          </div>
        </div>
      </Loader>
    );
  };

  const onAddInvestigatorToProject = (data) => {
    setSiteId(data.affiliated_site_id);
    setPiId(data.id);
    setAddInvestigatorToProjectModal(true);
    setFrom(1);
    setActiveModalTab('created');
  };

  const onOkClickModal = () => {
    const finalData = {
      project_id: projectId,
      site_id: siteId,
      pi_id: piId,
      is_cfl: adminType.includes('cfl_access') ? true : undefined,
      is_lcor: adminType.includes('lcor_access') ? true : undefined,
    };
    dispatch(addPiForCTAction(finalData));
  };

  const exportInvestigator = () => {
    const filename = `investigator_list_records.xlsx`;
    const params = {
      clinical_id: nctId,
      dataset: 'investigators',
      paymentFilter: filters,
    };
    Axios({
      url: `${process.env.apiUrl}v0/export/searchResults?${stringify(params)}`,
      method: 'GET',
      responseType: 'blob', // important
      headers: {
        Authorization: getCookie('accessToken'),
        'Content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'Access-Control-Allow-Origin': `${process.env.applicationUrl}`,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        return null;
      })
      .catch((e) => {
        return e;
      });
  };

  return (
    <>
    <div className="no-data-inside channel-data ct-deep-dive-investigator-data">
      {
        !isViewOnlyAccess() && (
          <div
          className="export-filter-icons"
          style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
        >
          <div className="export-to-csv-btn export-to-csv-network-btn">
            <Button
              className="export-to-csv-btn-icon export-networks-button"
              title="Export"
              onClick={() => exportInvestigator()}
              disabled={getSitePiDetailsFromTrialResponse.loading}
            />
          </div>
          <div className="filter-icon">
            <Popover
              overlayClassName="filter-studies-popover"
              placement="left"
              title=""
              content={renderFilters()}
              trigger="click"
              visible={isFiltersPopoverVisible}
              onVisibleChange={(visibled) => {
                setIsFiltersPopoverVisible(visibled);
              }}
              destroyTooltipOnHide
            >
              <Tooltip title="Filters">
                <div className="filter-icon-img" />
              </Tooltip>
            </Popover>
          </div>
        </div>
        )
      }
      <Loader
        loading={getSitePiDetailsFromTrialResponse.loading}
        error={getSitePiDetailsFromTrialResponse.error}
      >
        <div className="card-body-content">
          {tabsTrialDataObj?.length ? (
            tabsTrialDataObj?.map((itm) => {
              return (
                <div className="card clinical-trials-investigator-details card-with-no-border">
                  <div className="card-header card-header-notitle card-header-investigator">
                    <div className="content">
                      <div className="leftside">
                        <div
                          className={
                            itm.compliance === 'Compliance not preventing participation'
                              ? 'title compliance-flag-icon preventing'
                              : itm.compliance === 'Compliance preventing participation'
                              ? 'title compliance-flag-icon not-preventing'
                              : itm.compliance === 'Archived issue'
                              ? 'title compliance-flag-icon archive-issue'
                              : 'title'
                          }
                          title={
                            itm.compliance === 'Compliance not preventing participation'
                              ? 'Compliance not preventing participation'
                              : itm.compliance === 'Compliance preventing participation'
                              ? 'Compliance preventing participation'
                              : null
                          }
                        >
                          <Tooltip
                            title={
                              <div>
                                <div>
                                  Last modified by:{' '}
                                  {itm.last_modified_by_user_name
                                    ? itm.last_modified_by_user_name
                                    : '-'}
                                </div>
                                <div>
                                  Last modified on:{' '}
                                  {itm.last_modified_on
                                    ? getDatefromTimestamp(itm.last_modified_on)
                                    : '-'}
                                </div>
                              </div>
                            }
                          >
                            <a
                              target="_blank"
                              href={`/search/investigatorprofile?${stringify({
                                ...parse(location.search),
                                id: itm.id,
                                type: itm.type,
                              })}`}
                              rel="noopener noreferrer"
                            >
                              {itm.name}
                            </a>
                          </Tooltip>
                        </div>
                        <div className="info-ratings-sec-parent">
                          <div className="info-ratings-sec info-ratings-sec-left">
                            <div className="info-ratings-values">
                              <div className="info-ratings-values-title">Industry studies:</div>
                              <div className="info-ratings-values-data">{itm.industry_studies}</div>
                            </div>
                            <div className="info-ratings-values">
                              <div className="info-ratings-values-title">Parexel studies:</div>
                              <div className="info-ratings-values-data">{itm.parexel_studies}</div>
                            </div>
                            <div className="info-ratings-values">
                              <div className="info-ratings-values-title">Alliance type:</div>
                              {itm.alliance_type ? (
                                <div className="normal-text">{itm.alliance_type}</div>
                              ) : (
                                <div>-</div>
                              )}
                            </div>
                            <div className="info-ratings-values">
                              <div className="info-ratings-values-title">Alliance member:</div>
                              <div
                                className="info-ratings-values-data"
                                title={itm.alliance_member ? itm.alliance_member : '-'}
                              >
                                {itm.alliance_member ? itm.alliance_member : '-'}
                              </div>
                            </div>
                          </div>
                          <div className="info-ratings-sec info-ratings-sec-right">
                            <div className="info-ratings-values">
                              <div className="info-ratings-values-title">Tier:</div>
                              <div
                                className="info-ratings-values-data cursor-click"
                                role="presentation"
                                onClick={() => OnTierClick(itm.id)}
                              >
                                {itm.tier}
                              </div>
                            </div>
                            <div className="info-ratings-values">
                              <div className="info-ratings-values-title">Enrolled subjects:</div>
                              {itm.enrollment_subjects && itm.enrollment_subjects !== '' ? (
                                <div className="info-ratings-values-data">
                                  {itm.enrollment_subjects}
                                </div>
                              ) : (
                                <div className="info-ratings-values-data">0</div>
                              )}
                            </div>
                            <div className="info-ratings-values">
                              <div className="info-ratings-values-title">Reliability:</div>
                              <Tooltip
                                title={
                                  itm.reliability ? `Reliability score : ${itm.reliability}` : '-'
                                }
                              >
                                <div className="reliability-quality-rating">
                                  <div className="reliability rating-sec">
                                    {itm.reliability_class && itm.reliability_class !== '' ? (
                                      <StarRatings
                                        rating={getStars(itm.reliability_class)}
                                        starRatedColor="#8A0050"
                                        numberOfStars={3}
                                        starDimension="16px"
                                        starSpacing="1px"
                                        starEmptyColor="#D099B9"
                                      />
                                    ) : (
                                      <div className="content-child no-data">
                                        &nbsp;No data available
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </Tooltip>
                            </div>
                            <div className="info-ratings-values">
                              <div className="info-ratings-values-title">Quality:</div>
                              <div className="reliability-quality-rating">
                                <div className="reliability rating-sec">
                                  {itm.quality_rating !== '' ? (
                                    <StarRatings
                                      rating={itm.quality_rating}
                                      starRatedColor="#8A0050"
                                      numberOfStars={3}
                                      starDimension="16px"
                                      starSpacing="1px"
                                      starEmptyColor="#D099B9"
                                    />
                                  ) : (
                                    <div className="dash-nodata">-</div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="info-ratings-sec info-ratings-sec-right">
                            <div className="info-ratings-values">
                              <div className="info-ratings-values-title">
                                Last trial start date:
                              </div>
                              {itm.last_trial_start_date ? (
                                <div className="info-ratings-values-data plain-text no-ellipsis">
                                  {itm.last_trial_start_date}
                                </div>
                              ) : (
                                <div className="dash-nodata">-</div>
                              )}
                            </div>
                            <div className="info-ratings-values">
                              <div className="info-ratings-values-title">LandSCAPE ID:</div>
                              {itm.landscape_id ? (
                                <div className="Alliance member:-data plain-text no-ellipsis">
                                  {itm.landscape_id}
                                </div>
                              ) : (
                                <div className="dash-nodata">-</div>
                              )}
                            </div>
                            <div className="info-ratings-values">
                              <div className="info-ratings-values-title">Compliance:&nbsp;</div>
                              <div className="normal-text">{itm.compliance}</div>
                            </div>
                            <div className="info-ratings-values">
                              <div className="info-ratings-values-title">Sources:&nbsp;</div>
                              {itm.sources && itm.sources.length ? (
                                <div className="normal-text">{itm.sources.join('; ')}</div>
                              ) : (
                                <div>-</div>
                              )}
                            </div>
                          </div>
                          <div className="info-ratings-sec info-ratings-sec-right">
                            <div className="info-ratings-values">
                              <div className="info-ratings-values-title">NPI:&nbsp;</div>
                              {itm.npi ? <div>{itm.npi}</div> : <div>-</div>}
                            </div>
                            <div className="info-ratings-values">
                              <div className="info-ratings-values-title">SIMS IDs:&nbsp;</div>
                              {itm.pxl_author_sims_ids && itm.pxl_author_sims_ids.length ? (
                                <div className="info-ratings-values-data">
                                  {itm.pxl_author_sims_ids.join(', ')}
                                </div>
                              ) : (
                                <div className="info-ratings-values-data">-</div>
                              )}
                            </div>
                            <div className="info-ratings-values">
                              <div className="info-ratings-values-title">Citeline IDs:&nbsp;</div>
                              {itm.pxl_author_cit_ids && itm.pxl_author_cit_ids.length ? (
                                <div className="info-ratings-values-data">
                                  {itm.pxl_author_cit_ids.join(', ')}
                                </div>
                              ) : (
                                <div className="info-ratings-values-data">-</div>
                              )}
                            </div>
                            {itm.sources.indexOf('CLS') !== -1 ? (
                              <>
                                <div className="info-ratings-values info-ratings-values-more">
                                  <div
                                    className="info-ratings-values-title"
                                    title="KOL scores are derived based on research/information authored by the investigator like publications, clinical trials, congress articles, patents, grants, guidelines etc. Each document is scored considering several factors like type of document, recency of the document, role of the investigator in a given document, source where the document is published etc. Scores for each individual document is aggregated and weighted by data class, to calculate the raw KOL score. The raw KOL score for the investigators is then numerically scaled between 0 to 100 considering rest of the investigators in LandSCAPE."
                                  >
                                    KOL Score:&nbsp;
                                  </div>
                                  <div className="info-ratings-values info-ratings-values-more-align">
                                    {itm.cls_kol_score}
                                    {/* <Button onClick={onViewMoreKOLClick}>View more</Button> */}
                                  </div>
                                </div>
                              </>
                            ) : null}
                            <div className="info-ratings-values">
                              <div className="info-ratings-values-title">Total Payment:&nbsp;</div>
                              {itm.payment ? (
                                <div className="info-ratings-values-data">
                                  <div className="viewmore-combine">
                                    {itm.payment_currency} {itm.payment.toFixed(2)}
                                    {itm.cms_flag === 'High' ? (
                                      <span className="high-icon" style={{ marginTop: '7px' }} />
                                    ) : itm.cms_flag === 'Low' ? (
                                      <span className="low-icon" style={{ marginTop: '7px' }} />
                                    ) : itm.cms_flag === 'Medium' ?
                                    (
                                      <span className="medium-icon" style={{ marginTop: '7px' }} />
                                    ): null}
                                  </div>
                                </div>
                              ) : (
                                <div className="info-ratings-values-data">-</div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="rightside">
                        <div className="contact-details">
                          <div className="email">
                            <span className="title">Email:</span>
                            {itm.email && itm.email.length ? (
                              <>
                                <Tooltip
                                  className="copy-w-email"
                                  placement="bottom"
                                  title={itm.email.join(', ')}
                                >
                                  <span className="value">{itm.email.join(', ')}</span>
                                  <InputWithCopy text={itm} type="email" />
                                </Tooltip>
                              </>
                            ) : (
                              <span className="value" style={{ fontSize: '14px' }}>
                                No data available
                              </span>
                            )}
                            <span className="title">Survey Email:</span>
                            {itm.survey_email && itm.survey_email.length ? (
                              <Tooltip
                                placement="bottom"
                                className="copy-w-email"
                                title={itm.survey_email.join(', ')}
                              >
                                <span className="value">{itm.survey_email.join(', ')}</span>
                                <InputWithCopy text={itm} type="survey_email" />
                              </Tooltip>
                            ) : (
                              <span className="value" style={{ fontSize: '14px' }}>
                                No data available
                              </span>
                            )}
                          </div>
                          <div className="phone">
                            <span className="title">Phone:</span>
                            {itm.phone_number ? (
                              <Tooltip placement="bottom" title={itm.phone_number.join(', ')}>
                                <span className="value">{itm.phone_number.join(', ')}</span>
                              </Tooltip>
                            ) : (
                              <span className="value" style={{ fontSize: '14px' }}>
                                No data available
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="chart-sec">
                          <div className="chart-data">
                            <div className="chart-content">
                              <span className="chart-title">Ongoing Studies:</span>
                              <span className="chart-value">{itm.ongoing_studies}</span>
                            </div>
                            <div className="chart-content">
                              <div className="primary-org">
                                <span className="title chart-title">
                                  Parent Institution Name:&nbsp;
                                </span>
                                {Object.keys(itm.parent_institution) &&
                                Object.keys(itm.parent_institution).length ? (
                                  <a
                                    target="_blank"
                                    href={`/search/deepdives?${stringify({
                                      ...parse(location.search),
                                      id: itm.parent_institution.id,
                                      currentTab: 'parent_centers',
                                    })}`}
                                    rel="noopener noreferrer"
                                  >
                                    {itm.parent_institution.name}
                                  </a>
                                ) : (
                                  <span className="value" style={{ fontSize: '14px' }}>
                                    No data available
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="chart-content">
                              <div className="primary-org">
                                <span className="title chart-title">Primary Center:&nbsp;</span>
                                {itm.affiliated_site ? (
                                  <Tooltip placement="bottom" title={itm.affiliated_site}>
                                    <a
                                      target="_blank"
                                      href={`/search/deepdives?${stringify({
                                        ...parse(location.search),
                                        currentTab: 'centers',
                                        id: itm.affiliated_site_id,
                                        type: 'SITE',
                                        query: itm.affiliated_site,
                                      })}`}
                                      rel="noopener noreferrer"
                                      className="chart-value"
                                    >
                                      {itm.affiliated_site}
                                    </a>
                                  </Tooltip>
                                ) : (
                                  <span className="value" style={{ fontSize: '14px' }}>
                                    No data available
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="chart-content">
                              <span className="chart-title">Primary Center Address:</span>
                              <Tooltip
                                placement="bottom"
                                title={`${itm?.primary_organization?.address_line1}, ${itm?.primary_organization?.address_line2},${itm?.primary_organization?.city},${itm?.primary_organization?.state},${itm?.primary_organization?.country}`}
                              >
                                {itm.primary_organization && itm.primary_organization.city ? (
                                  <div className="chart-value">
                                    {itm?.primary_organization?.address_line1 &&
                                      `${itm.primary_organization.address_line1}, `}
                                    {itm.primary_organization.address_line2 &&
                                      `${itm.primary_organization.address_line2}, `}
                                    {itm.primary_organization.city &&
                                      `${itm.primary_organization.city}, `}
                                    {itm.primary_organization.state &&
                                      `${itm.primary_organization.state}, `}
                                    {itm.primary_organization.country &&
                                      `${itm.primary_organization.country}, `}
                                    {itm.primary_organization.zip}
                                  </div>
                                ) : (
                                  <div className="chart-value no-data">No data available</div>
                                )}
                              </Tooltip>
                            </div>

                            <div className="chart-content">
                              <span className="chart-title">Specialties:</span>
                              <Tooltip
                                placement="bottom"
                                title={
                                  itm.specialties && itm.specialties.length
                                    ? itm.specialties.join('; ')
                                    : ''
                                }
                              >
                                <span className="chart-value">
                                  <ReadMore type="text" limit={100}>
                                    {itm.specialties && itm.specialties.length
                                      ? itm.specialties.join('; ')
                                      : ''}
                                  </ReadMore>
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {
                      !isViewOnlyAccess() && (
                        <div className="add-investigator-sec">
                        <Button
                          className="add-investigator-sec-btn"
                          onClick={() => onAddInvestigatorToProject(itm)}
                        >
                          Add to project
                        </Button>
                      </div>
                      )
                    }
                  </div>
                </div>
              );
            })
          ) : (
            <div className="no-data-inside-ct">No data found</div>
          )}
        </div>
      </Loader>
      {addInvestigatorToProjectModal ? (
        <Modal
          open={addInvestigatorToProjectModal}
          className="myproject-sharedpro-modal"
          // style={{ width: '1000px' }}
          onOk={onOkClickModal}
          onCancel={() => {
            setProjectId('');
            setPiId('');
            setSiteId('');
            setId('');
            setAddInvestigatorToProjectModal(false);
          }}
          okButtonProps={{ disabled: projectId === '' }}
        >
          <Loader loading={addPiResponse.loading}>
            <div className="myproject-sharedpro-tabs">
              <div
                role="presentation"
                // className="tabs-btn active"
                className={activeModalTab === 'created' ? 'tabs-btn active' : 'tabs-btn'}
                onClick={() => {
                  // settableData([])
                  setActiveModalTab('created');
                  setFrom(1);
                  setLoading(true);
                  setActiveToggleReview('all');
                  setActiveToggle('all');
                  // setTableDataForSharedProject([])
                }}
              >
                My Projects ({countsData.my_project_count})
              </div>
              <div
                role="presentation"
                className={activeModalTab === 'shared' ? 'tabs-btn active' : 'tabs-btn'}
                onClick={() => {
                  setActiveModalTab('shared');
                  setFrom(1);
                  setLoading(true);
                  setActiveToggleReview('all');
                  setActiveToggle('all');
                }}
              >
                Shared With Me ({countsData.shared_with_me_count})
              </div>
            </div>
            <div className="myproject-sharedpro-modal-table">
              {activeModalTab === 'created' ? renderMyProject() : renderSharedProject()}
            </div>
          </Loader>
        </Modal>
      ) : (
        ''
      )}
      {warningModal ? (
        <Modal
          onCancel={() => setWarningModal(false)}
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}
          open={warningModal}
          className="add-project-warning-modal"
        >
          <div className="add-project-warning-title">
            You can not add the PI to the project because you do not have owner access.{' '}
          </div>
        </Modal>
      ) : (
        ''
      )}
    </div>
    {
      tabsTrialDataObj.length > 0 && (
        <div className="show-doc-outof show-doc-outof-w-relative" style={{ marginBottom: '10px' }}>
        <div className="show-doc-data">
          Showing<span className="show-count">{tabsTrialDataObj.length}</span>Investigators
        </div>
      </div>
      )
    }
    </>
  );
}
